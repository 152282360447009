import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { showalert } from "../components/Helpers/Utils";

const CommentList = () => {
  const history = useHistory();
  const [loadedComments, setLoadedComments] = useState([]);
  let request_id = localStorage.getItem("request_id");

  function popup(e) {
    let modal = document.getElementById("myModal");
    let modalImg = document.getElementById("img01");
    let span = document.getElementById("close");
    let rl = e.target.getAttribute("src");
    modalImg.src = rl;

    modal.style.display = "block";

    span.onclick = function () {
      modal.style.display = "none";
    };
  }

  useEffect(() => {
    const spinner = document.getElementById("spinner");
    spinner.style.display = "block";
    comments()
      .then((data) => {
        setLoadedComments(data);
        spinner.style.display = "none";
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const comments = async () => {
    const response = await fetch(
      "https://www.innovations.co.ke/portalapi/public/api/viewComments/" +
        request_id
    );
    if (response.status !== 200) {
      throw new Error(
        "There was an error fetching comments :" + response.status
      );
    }
    const data = await response.json();
    return data;
  };

  function closeTicket() {
    requestClose()
      .then((data) => {
        showalert(data, "success");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const requestClose = async () => {
    const url =
      "https://www.innovations.co.ke/portalapi/public/api/closeRequest/" +
      request_id;
    const options = {
      method: "PUT",
    };
    const response = await fetch(url, options);
    if (response.status !== 200) {
      throw new Error("There was an error closing ticket:" + response.status);
    }
    const data = await response.json();
    return data;
  };

  return (
    <div className="d-grid gap-2" style={{ marginBottom: "5%" }}>
      <div className="alert alert-success h2 text-center fw-bolder">
        <span
          className="text-info h1"
          role="button"
          style={{ float: "left" }}
          onClick={() => history.goBack()}
        >
          <i class="fas fa-arrow-left "></i>
        </span>
        Comment List
        <span
          className="btn btn-success"
          onClick={closeTicket}
          id="closeTicket"
          style={{ float: "right" }}
        >
          Close Ticket
        </span>
      </div>
      {loadedComments.map((dt) => {
        if (!loadedComments || !loadedComments.length) {
          document.getElementById("closeTicket").style.display = "none";
        } else {
          document.getElementById("closeTicket").style.display = "inline";
        }
        if (dt.user_type == "client") {
          return (
            <div className="col-md-12">
              <div className="col-md-10  mx-auto">
                <div className="text-primary fw-bolder col-md-12 text-uppercase">
                  {dt.user_name}
                </div>
                <div className="text-secondary fw-lighter col-md-12">
                  {dt.comment_date}
                </div>
                <div>
                  <div
                    style={{
                      whiteSpace: "nowrap",
                      overflowX: "scroll",
                      width: "800px",
                    }}
                  >
                    {dt.img_url.length != 0
                      ? dt.img_url.map((imgurl) => {
                          return (
                            <img
                              className="p-3 bg-light border border-success rounded myImg"
                              onClick={popup}
                              src={imgurl}
                              width="200px"
                              height="200px"
                              style={{ margin: "5px" }}
                            />
                          );
                        })
                      : ""}
                    {dt.file_url.length != 0
                      ? dt.file_url.map((fileUrl) => {
                          return (
                            <a href={fileUrl} style={{ margin: "10px" }}>
                              <button className="btn btn-success">
                                {fileUrl.substring(
                                  fileUrl.lastIndexOf("/") + 1
                                )}
                              </button>
                            </a>
                          );
                        })
                      : ""}
                  </div>
                </div>
                <div className="p-3 bg-light border border-primary rounded col-md-12">
                  {dt.comment_note}
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div className="col-md-12">
              <div className="col-md-10 float-end  mx-auto">
                <div className="text-warning fw-bolder col-md-12 text-uppercase">
                  {dt.user_name}
                </div>
                <div className="text-secondary fw-lighter col-md-12">
                  {dt.comment_date}
                </div>
                <div>
                  {dt.img_url.length != 0 ? (
                    <div
                      style={{
                        whiteSpace: "nowrap",
                        overflowX: "scroll",
                        width: "800px",
                      }}
                    >
                      {dt.img_url.map((imgurl) => {
                        return (
                          <img
                            className="p-3 bg-light border border-success rounded myImg"
                            onClick={popup}
                            src={imgurl}
                            width="200px"
                            height="200px"
                            style={{ margin: "5px" }}
                          />
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="p-3 bg-light border border-warning rounded col-md-12">
                  {dt.comment_note}
                </div>
              </div>
            </div>
          );
        }
      })}
      <div id="myModal" class="modal" tabindex="-1">
        <div class="modal-dialog modal-fullscreen modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                id="close"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <img id="img01" width="100%" height="100%" />
            </div>
          </div>
        </div>
      </div>
      <div
        class="spinner-border text-info text-center mx-auto"
        role="status"
        id="spinner"
        style={{
          width: "3rem",
          height: "3rem",
          display: "none",
          marginTop: "20%",
        }}
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

export default CommentList;
