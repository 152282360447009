const TimeRemaining = (props) => {
  if (props.status !== "closed") {
    const countDownDate = new Date(props.future).getTime();
    let now = new Date().getTime();
    let distance = countDownDate - now;
    let days = Math.ceil(distance / (1000 * 60 * 60 * 24));

    if (days === 0) {
      return <span className="text-warning h5 fw-bolder">today</span>;
    } else if (days < 0) {
      return <span className="text-danger h5 fw-bolder">Times up</span>;
    } else if (days === 1) {
      return <span className="text-primary h5 fw-bolder">{days} day</span>;
    } else {
      return <span className="text-primary h5 fw-bolder">{days} days</span>;
    }
  } else {
    return  <span className="text-success h6 fw-bolder">Task Completed</span>;
  }
};

export default TimeRemaining;
