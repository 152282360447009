import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import PortalBody from "../components/layouts/PortalBody";

const Login = () => {
  const loginDev = () => {
    const formLogin = document.getElementById("formLogin");
    formLogin.addEventListener("submit", (e) => {
      const formData = new FormData(formLogin);
      fetchDev(formData)
        .then((data) => {
          if (data.length !== 0) {
            data.map((dt) => {
               if (typeof Storage !== "undefined") {
                 localStorage.setItem("dev_id", dt.developer_id);
                 localStorage.setItem("dev_email", dt.email);
                 localStorage.setItem("dev_name", dt.name);
                 localStorage.setItem("client_id", dt.client_id);
               } else {
                 console.log("could not save developer data in local storage");
               }
              ReactDOM.render(
                <BrowserRouter>
                  <PortalBody email={dt.email} name={dt.name} />
                </BrowserRouter>,
                document.getElementById("root")
              );
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
      e.preventDefault();
    });
  };

  const fetchDev = async (formDt) => {
    const url = "https://www.innovations.co.ke/portalapi/public/api/developerLogin";
    const options = {
      method: "POST",
      body: formDt,
    };
    const response = await fetch(url, options);
    if (response.status !== 200) {
      throw new Error(
        "There was an error fetching Developer :" + response.status
      );
    }
    const data = await response.json();
    return data;
  };  

  return (
    <div className="card col-md-4 mx-auto" style={{ marginTop: "20%" }}>
      <div className="card-header text-center bg-white">
        <h1>
          <span className="text-danger fw-bolder">Login </span>
        </h1>
      </div>
      <div className="card-body">
        <form id="formLogin" className="d-grid gap-3">
          <div className="form-group" id="formLogin">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              className="form-control"
              autoComplete="off"
              id="email"
              name="email"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              className="form-control"
              autoComplete="off"
              id="password"
              name="password"
              required
            />
          </div>
          <button
            type="submit"
            id="submit"
            onClick={loginDev}
            className="btn btn-secondary mx-auto fs-5"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
