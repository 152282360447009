import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";

const TicketDetailsPage = (props) => {
  let history = useHistory();
  const [loadedComments, setLoadedComments] = useState([]);

  function showSummary() {
    history.push({
      pathname: "/ticket-summary",
    });
  }
  function createComment() {
    history.push({
      pathname: "/create-comment",
    });
  }
  function commentList() {
    history.push({
      pathname: "/comment-list",
    });
  }

  useEffect(() => {
    comments()
      .then((data) => {
        setLoadedComments(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const comments = async () => {
    const response = await fetch(
      "https://www.innovations.co.ke/portalapi/public/api/countComments/" +
        props.id
    );
    if (response.status !== 200) {
      throw new Error(
        "There was an error fetching total comments :" + response.status
      );
    }
    const data = await response.json();
    return data;
  };
  return (
    <div style={{ marginTop: "15%" }}>
      <div className="col-md-6 mx-auto">
        <ul class="list-group text-center fw-bold h3 d-grid gap-5">
          <li
            class="list-group-item list-group-item-primary"
            role="button"
            onClick={showSummary}
          >
            Ticket Summary
          </li>
          <li
            class="list-group-item list-group-item-success"
            role="button"
            onClick={createComment}
          >
            Create Comment
          </li>
          <li
            class="list-group-item list-group-item-info"
            role="button"
            onClick={commentList}
          >
            Comment List
            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
              {loadedComments}
              <span class="visually-hidden">unread messages</span>
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TicketDetailsPage;
