import ReactDOM from "react-dom";
import Login from "./pages/Login";
import PortalBody from "./components/layouts/PortalBody";
import { BrowserRouter } from "react-router-dom";


function App() {
  const loginHandler = () => {
    return ReactDOM.render(<Login />, document.getElementById("root"));
  };

   if (
     localStorage.getItem("dev_email") !== null ||
     localStorage.getItem("dev_name") !== null
   ) {
     return ReactDOM.render(
       <BrowserRouter>
         <PortalBody
           email={localStorage.getItem("dev_email")}
           name={localStorage.getItem("dev_name")}
         />
       </BrowserRouter>,
       document.getElementById("root")
     );
   } else {
     return (
       <div className="col-md-12 text-center" style={{ marginTop: "20%" }}>
         <p className=" display-5 text-info fw-bolder">
           Welcome to
           <span className="text-danger fw-bolder"> HAIS</span> request portal
         </p>
         <button
           id="proceed"
           className="btn btn-secondary col-md-1"
           onClick={loginHandler}
         >
           Proceed <i className="far fa-hand-point-right text-info h5"></i>
         </button>
       </div>
     );
   }
}

export default App;
