import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { showalert } from "../components/Helpers/Utils";
import Mandatory from "../components/Helpers/Mandatory";

const CreateComment = () => {
  const history = useHistory();

  useEffect(() => {
    const frmComments = document.getElementById("frmComments");
    frmComments.addEventListener("submit", (e) => {
      const formData = new FormData(frmComments);
      formData.append("request_id", localStorage.getItem("request_id"));
      formData.append("user_email", localStorage.getItem("dev_email"));
      formData.append("user_name", localStorage.getItem("dev_name"));
      formData.append("user_type", "developer");

      commentCreate(formData)
        .then((data) => {
          showalert(data, "success");
        })
        .catch((error) => {
          console.log(error);
        });
      e.preventDefault();
    });
  }, []);

  const commentCreate = async (formDt) => {
    const url =
      "https://www.innovations.co.ke/portalapi/public/api/createComment";
    const options = {
      method: "POST",
      body: formDt,
    };
    const response = await fetch(url, options);
    if (response.status !== 200) {
      throw new Error(
        "There was an error creating Comment :" + response.status
      );
    }
    const data = await response.json();
    return data;
  };

  function countCharacters(val) {
    document.getElementById("charCount").innerHTML =
      "Characters left : " + (2000 - val.length);
  }

  return (
    <div style={{ marginTop: "2%", marginBottom: "5%" }}>
      <div className="alert alert-success h2 text-center fw-bolder">
        <span
          className="text-info h1"
          role="button"
          style={{ float: "left" }}
          onClick={() => history.goBack()}
        >
          <i class="fas fa-arrow-left "></i>
        </span>
        Create Comment
      </div>
      <form
        id="frmComments"
        className="d-grid gap-2"
        encType="multipart/form-data"
      >
        <div className="form-group">
          <label htmlFor="fileToUpload" className="text-info fw-bolder">
            Image (choose an image with max size of 2mb)
          </label>
          <input
            type="file"
            className="form-control"
            autoComplete="off"
            id="comment_img"
            name="comment_img[]"
            multiple="multiple"
            accept="image/*"
          />
        </div>
        <div className="form-group">
          <label htmlFor="otherFileToUpload" className="text-info fw-bolder">
            File (choose a file of type doc, excel, pdf, or csv )
          </label>
          <input
            type="file"
            className="form-control"
            autoComplete="off"
            id="otherFileToUpload"
            name="otherFileToUpload[]"
            multiple="multiple"
            accept="text/html, text/plain, .pdf, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
        </div>
        <div className="form-group">
          <label htmlFor="password" className="text-info fw-bolder">
            Comment (max 2000 characters)
            <Mandatory />
          </label>
          <span
            className="text-success"
            style={{ float: "right" }}
            id="charCount"
          >
            Characters left : 2000
          </span>
          <textarea
            type="text"
            rows="10"
            className="form-control"
            autoComplete="off"
            id="comment"
            name="comment"
            minLength="5"
            maxlength="2000"
            onKeyUp={(e) => countCharacters(e.target.value)}
            required
          />
        </div>

        <button
          type="submit"
          id="submit"
          className="btn btn-info text-white fw-bolder mx-auto fs-5"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default CreateComment;
