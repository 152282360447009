import { Route, Switch } from "react-router-dom";
import ViewTickets from "../../pages/ViewTickets";
import TicketDetails from "../../pages/TicketDetails";
import TicketSummary from "../../pages/TicketSummary";
import CreateComment from "../../pages/CreateComment";
import CommentList from "../../pages/CommentList";
import PassChange from "../../pages/PassChange";
import MainNavigation from "./MainNavigation"


const PortalBody = (props) => {
  return (
    <div>
      <MainNavigation email={props.email} name={props.name} />
      <div id="content">
        <Switch>
          <Route path="/" exact>
            <ViewTickets />
          </Route>
          <Route path="/ticket-details">
            <TicketDetails />
          </Route>
          <Route path="/create-comment">
            <CreateComment />
          </Route>
          <Route path="/ticket-summary">
            <TicketSummary />
          </Route>
          <Route path="/comment-list">
            <CommentList />
          </Route>
          <Route path="/pass-change">
            <PassChange />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default PortalBody;
