import { useState, useEffect } from "react";
import TicketTable from "./TicketTable";

const ViewTickets = () => {
  const client_Id = localStorage.getItem("client_id");
  const [loadedRequests, setLoadedRequests] = useState([]);

  useEffect(() => {
    requests()
      .then((data) => {
        setLoadedRequests(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const requests = async () => {
    const response = await fetch(
      "https://www.innovations.co.ke/portalapi/public/api/viewTickets/" + client_Id
    );
    if (response.status !== 200) {
      throw new Error("There was an error fetching Tickets :" + response.status);
    }
    const data = await response.json();
    return data;
  };

  return <TicketTable loadedRequests={loadedRequests} />;

};

export default ViewTickets;
