import { useHistory } from "react-router-dom";
import TimeRemaining from "../components/Helpers/TimeRemaining";
import Progress from "../components/Helpers/Progress";

const TicketTable = (props) => {
  let history = useHistory();

  function getDetails(event) {
    let rowValues = [];
    let child = event.target.parentElement.parentElement.children;
    for (let x of child) {
      rowValues.push(x.textContent);
    }

    if (rowValues[9] === "Pending") {
      console.log(child);
      updateStatus(rowValues[0]);
    }

    history.push({
      pathname: "/ticket-details",
      state: {
        response: rowValues,
      },
    });
  }

  const updateStatus = (request_id) => {
    statusUpdate(request_id)
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const statusUpdate = async (request_id) => {
    const response = await fetch(
      "https://innovations.co.ke/portalapi/public/api/updateRequestStatus/" + request_id,
      { method: "POST" }
    );
    if (response.status !== 200) {
      throw new Error("There was an error updating status :" + response.status);
    }
    const data = await response.json();
    return data;
  };

  return (
    <div className="table-responsive" style={{ marginTop: "5%" }}>
      <table className="table table-bordered table-hover">
        <thead className="table-secondary text-info">
          <tr>
            <th style={{ display: "none" }}>Request Id</th>
            <th>Client Name</th>
            <th>Client Contact Name</th>
            <th>Subject</th>
            <th>Priority</th>
            <th>Creation Date</th>
            <th>Expected Completion Date</th>
            <th>Delivery Date</th>
            <th>Time Remaining</th>
            <th>Ticket Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {props.loadedRequests.map((dt) => {
            return (
              <tr>
                <td style={{ display: "none" }}> {dt.request_id}</td>
                <td>{dt.client_name}</td>
                <td>{dt.contact_name}</td>
                <td>{dt.subject}</td>
                <td>{dt.priority}</td>
                <td>{dt.request_date}</td>
                <td>{dt.expected_completion_date}</td>
                <td>{dt.delivery_date}</td>
                <td>
                  <TimeRemaining
                    future={dt.expected_completion_date}
                    now={dt.request_date}
                    status={dt.request_progress}
                  />
                </td>
                <td><Progress progress={dt.request_progress} /></td>
                <td>
                  <button className="btn btn-success view" onClick={getDetails}>
                    View
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TicketTable;
